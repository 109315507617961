import React from 'react'
import {Container, Card, Col, Row, Form, Button, Alert } from 'react-bootstrap'

export default function DetailReport({message, getReport, setEmail}) {
    return (
      
        <Container>
          <Row className="mt-5">
            <Col className="row justify-content-md-center">
              <Card
                bg="secondary"
                text="light"
                className="border-0"
                style={{ width: "40rem" }}
              >
                <Card.Title className="mt-2 ml-2">
                  {" "}
                  Simulate Historical Performance of Your Portfolio{" "}
                </Card.Title>
                <Card.Body className="mt-2 ml mr mb bg-light text-dark">
                  <ul>
                    <li>This module is a work-in-progress. </li>
                    <li>
                      Please leave us your email address and you'll receive a
                      link to the report.
                    </li>
                    <li>
                      This report includes portfolio performance / risk profile
                      in the past 20 years.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="row justify-content-md-center">
              <Card
                bg="secondary"
                text="light"
                className="border-0"
                style={{ width: "20rem" }}
              >
                <Card.Title className="mt-2 ml-2">
                  {" "}
                  Send me a detailed report
                </Card.Title>
                <Card.Body className="mt-2 ml mr mb bg-light text-dark">
                  {" "}
                  <Form>
                    {" "}
                    <Form.Group>
                      <Form.Control
                        size="sm"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="E-mail"
                      />
                    </Form.Group>
                    <Button onClick={getReport}> Submit </Button>
                  </Form>
                  {message !== "" && (
                    <Alert
                      className="mt-2"
                      variant={
                        message === "Received and Thank you!"
                          ? "success"
                          : "danger"
                      }
                    >
                      {message}
                    </Alert>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          </Container>
    )
}
