import React, { useState } from "react";
import { Row, Col, Card, Form, Button, Badge, Modal } from "react-bootstrap";
import Footer from "./GuruFormFooter";
import GuruPerformanceTable from "./GuruPerformanceTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDice, faPowerOff } from "@fortawesome/free-solid-svg-icons";

export default function GuruForm({
  allGurus,
  handleChangeAllocation,
  pctToBeAllocated,
  handleNext,
  guruPctString,
  handleMixUp,
  handleReset,
  alertMsg,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleNext(2, 1);
  };

  const [showGuru, setShowGuru] = useState(-1);

  return (
    <React.Fragment>
      <Modal show={showGuru >= 0} onHide={() => setShowGuru(-1)}>
        <Modal.Header>
          <h4>{allGurus[showGuru] && allGurus[showGuru].name}</h4>
        </Modal.Header>
        <Modal.Body>
          {allGurus[showGuru] && allGurus[showGuru].description}{" "}
          <a
            href={allGurus[showGuru] && allGurus[showGuru]["description link"]}
            target="_blank"
          >
            More
          </a>
        </Modal.Body>
      </Modal>
      <Row className="justify-content-center mt-2 ml-1 mr-1">
        <Col
          className="d-flex justify-content-md-end justify-content-center mt-2"
          xs={12}
          md={6}
        >
          <Badge variant="">
            <h5 className="ml-1 mr-1 mt-1">
              <b>Select percent investment with each investor</b>
            </h5>
          </Badge>
        </Col>
        {/* </Row>
      <Row className="justify-content-center mt-4"> */}
        <Col
          className="d-flex justify-content-md-start justify-content-center mt-2"
          xs={12}
          md={6}
        >
          <Button variant="outline-danger" onClick={handleMixUp}>
            <FontAwesomeIcon icon={faDice} /> Mix it up
          </Button>
          <Button className="ml-4" variant="outline-dark" onClick={handleReset}>
            <FontAwesomeIcon icon={faPowerOff} /> Reset
          </Button>
        </Col>
      </Row>

      <Row>
        {allGurus &&
          allGurus.map((guru, i) => (
            <Col xs={12} md={6} lg={4} xl={3} className="mt-4" key={i}>
              <Card className="ml-1 h-100" key={guru.name}>
                <Card.Header className="d-flex justify-content-center">
                  <Button
                    className=""
                    variant="link"
                    onClick={() => setShowGuru(i)}
                  >
                    <b>{guru.name}</b>
                  </Button>{" "}
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  <>
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => setShowGuru(i)}
                    >
                      {/* <b>{guru.name}</b> */}
                    </Button>{" "}
                    {guru["short description"]}
                  </>

                  <GuruPerformanceTable guru={guru} />
                </Card.Body>
                <Card.Footer>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row}>
                      <Form.Label as={Col}>
                        <b>Allocation (%)</b>
                      </Form.Label>
                      <Col>
                        <Form.Control
                          size="sm"
                          value={guruPctString[i]}
                          onChange={(e) => {
                            handleChangeAllocation(i, e.currentTarget.value);
                          }}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </Form>
                </Card.Footer>
              </Card>
            </Col>
          ))}
      </Row>
      <Footer
        pctToBeAllocated={pctToBeAllocated}
        handleNext={handleNext}
        alertMsg={alertMsg}
      />
    </React.Fragment>
  );
}
