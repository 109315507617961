import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  Alert,
  Card,
  Image,
} from "react-bootstrap";
import InvestAmtFormFooter from "./InvestAmtFormFooter";

export default function InvestAmt({
  setPortAmt,
  portAmt,
  handleNext,
  alertMsg,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleNext(1, 1);
  };

  const [showAlert, setShowAlert] = useState(false);
  const [portAmtString, setPortAmtString] = useState(portAmt.toLocaleString());

  useEffect(() => {
    if (alertMsg.trim().length > 0) setShowAlert(true);
    else setShowAlert(false);
    return () => {};
  }, [alertMsg]);

  const handleChangePortAmt = (e) => {
    const targetValue = e.currentTarget.value.replaceAll(",", "");
    // console.log(targetValue, parseFloat(targetValue).toLocaleString());
    if (parseFloat(targetValue).toLocaleString() === "NaN") {
      setPortAmtString("");
      setPortAmt(0);
    } else {
      setPortAmtString(parseFloat(targetValue).toLocaleString());
      setPortAmt(parseFloat(targetValue));
    }
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center align-items-center bg-light">
        <Col>
          <Row className="justify-content-center align-items-center mb-3 mt-sm-3 mt-md-5"></Row>
          <Row className="justify-content-center align-items-center mt-2">
            <Form onSubmit={handleSubmit} className="mr-3">
              <Form.Group className="text-center">
                <Form.Label>
                  <h2>
                    <b>I am investing</b>
                  </h2>
                </Form.Label>

                <InputGroup className="mt-2" size="lg">
                  <InputGroup.Text id="basic">
                    <b>$</b>
                  </InputGroup.Text>
                  <Form.Control
                    className="form-control-lg text-center font-weight-bold"
                    //defaultValue={portAmt > 0 ? portAmt : null}
                    value={portAmtString}
                    onChange={handleChangePortAmt}
                  ></Form.Control>
                </InputGroup>
              </Form.Group>
            </Form>
          </Row>
          {showAlert && (
            <Row className="justify-content-center align-items-center mt-3">
              <Alert
                variant="danger"
                show={showAlert}
                onClose={() => setShowAlert(false)}
                dismissible
              >
                {alertMsg}
              </Alert>
            </Row>
          )}
          <Row className="justify-content-center align-items-center mt-3 mb-3">
            <Button type="submit" variant="primary" onClick={handleSubmit}>
              <b>Start</b>
            </Button>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col xs={12} sm={8} md={4} xl={3}>
          <Card className="mt-1 ml-2 mr-2 bg-white border-0 border-dark">
            <Card.Header className="text-center bg-white text-primary" as="h1">
              One
            </Card.Header>
            <Card.Body className="text-center bg-white">
              {" "}
              <h5>Tell us how much you are investing</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={8} md={4} xl={3}>
          <Card className="mt-1 ml-2 mr-2 bg-white border-0 border-dark">
            {" "}
            <Card.Header className="text-center bg-white text-primary" as="h1">
              Two
            </Card.Header>
            <Card.Body className="text-center bg-white">
              <h5>Choose your favorite investment guru</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={8} md={4} xl={3}>
          <Card className="mt-1 ml-2 mr-2 bg-white border-0 border-dark">
            {" "}
            <Card.Header className="text-center bg-white text-primary" as="h1">
              Three
            </Card.Header>
            <Card.Body className="text-center bg-white">
              <h5>We customize your investment portfolio</h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5 justify-content-center text-center bg-white">
        <Col xs={8} md={4}>
          <Card className="border border-primary text-secondary" body={true}>
            <Image
              style={{ maxWidth: 45, height: "auto" }}
              src="financial-profit.svg"
              fluid={true}
            />
            <h4 className="mt-3"> Invest like a champ -- for free!</h4>
          </Card>
        </Col>
      </Row>
      <InvestAmtFormFooter />
    </React.Fragment>
  );
}
