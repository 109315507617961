import React, { useState, useEffect } from "react";
import axios from "axios";
import MySpinner from "./common/MySpinner";
import { endpoint } from "../constants";
import { useParams } from "react-router-dom";
import SimpleLine from "./common/SimpleLine";
import { Container, Row, Col, Table, Tab, Tabs } from "react-bootstrap";
import ShowTicker from "./common/ShowTicker";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LineIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
  FacebookMessengerShareButton,
} from "react-share";

export default function ReadReport(props) {
  const [address, setAddress] = useState(useParams().address);
  const [result, setResult] = useState([]);
  const [assets, setAssets] = useState([]);
  const [error, setError] = useState(false);
  const [totalRet, setTotalRet] = useState({ Average: 0, Market: 0 });
  const [avgRet, setAvgRet] = useState({ Average: 0, Market: 0 });
  const [std, setStd] = useState({ Average: 0, Market: 0 });

  const [key, setKey] = useState();
  const [thisAddress, setThisAddress] = useState(
    `https://portdoc.web.app/${useParams().address}`
  );

  useEffect(() => {
    const headers = { "Content-Type": "application/json" };
    //console.log(address);
    axios
      .post(endpoint + `/readreport`, { address: address }, headers)
      .then((res) => {
        //console.log(res);
        if (res === undefined || res === null) {
          setError(true);
        } else {
          const data = res.data.result;
          //console.log(data);
          data.sort((a, b) => (a.date > b.date ? 1 : -1));
          const data1 = data.map((val) => ({
            Date: val.date,
            Average: Math.round(val.avg * 100) / 100,
            High: Math.round(val.high * 100) / 100,
            Low: Math.round(val.low * 100) / 100,
            Market: Math.round(val.mkt * 100) / 100,
          }));
          setResult(data1);
          setAssets(res.data.assets);
          //console.log(res.data)
          return data1;
        }
      })
      .then((val) => {
        return [
          val.length,
          val.map((v) => ({ Average: v.Average, Market: v.Market })),
        ];
      })
      .then((val) => {
        // calculate average return, annual standard deviation
        return [
          val[1],
          val[1].reduce(
            (acc, curr) => {
              return {
                Average: (1 + acc.Average) * (1 + curr.Average / 100) - 1,
                Market: (1 + acc.Market) * (1 + curr.Market / 100) - 1,
              };
            },
            {
              Average: 0,
              Market: 0,
            }
          ),
          val[0],
        ];
      })
      .then((val) => {
        setTotalRet(val[1]);
        return [
          val[0],
          val[0].reduce((acc, curr) => {
            return {
              Average: acc.Average + curr.Average,
              Market: acc.Market + curr.Market,
            };
          }),
          val[2],
        ];
      })
      .then((val) => {
        setAvgRet({
          Average: val[1].Average / val[0].length,
          Market: val[1].Market / val[0].length,
        });
        return [
          val[0].map((v) => ({
            Average: Math.pow(
              v.Average / 100 - val[1].Average / 100 / val[0].length,
              2
            ),
            Market: Math.pow(
              v.Market / 100 - val[1].Market / 100 / val[0].length,
              2
            ),
          })),
          val[2],
        ];
      })
      .then((val) => {
        return [
          val[0].reduce((acc, curr) => {
            return {
              Average: acc.Average + curr.Average,
              Market: acc.Market + curr.Market,
            };
          }),
          val[1],
        ];
      })
      .then((val) => {
        setStd({
          Average: Math.pow((val[0].Average * 12) / val[1], 0.5),
          Market: Math.pow((val[0].Market * 12) / val[1], 0.5),
        });
      });
    //.catch(setError(true));
    return () => {};
  }, [address]);

  if (error) return <h2> We cannot find this report</h2>;
  if (result === []) {
    return <MySpinner />;
  } else
    return (
      <Container className="mt-3">
        <Row className="mt-3 mb-3">
          <Col className="row justify-content-md-center">
            <h5 className="mr-3"> Share this report </h5>
            <EmailShareButton url={thisAddress}>
              <EmailIcon size={32} round={true} />
            </EmailShareButton>
            <FacebookShareButton url={thisAddress}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={thisAddress}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <RedditShareButton url={thisAddress}>
              <RedditIcon size={32} round={true} />
            </RedditShareButton>
          </Col>
        </Row>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="home" title="Portfolio Report">
            <Row className="mt-3">
              <Table striped bordered hover className="mt-4" size="sm">
                <thead>
                  <tr>
                    <th>Investment</th>
                    <th>Average Return</th>
                    <th>Standard Deviation</th>
                    <th>Value in 2020 if $100 invested in 2000 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Your Portfolio * </td>
                    <td>{Math.round(avgRet.Average * 12 * 100) / 100}%</td>
                    <td>{Math.round(std.Average * 100)}% </td>
                    <td>${Math.round(totalRet.Average * 10000) / 100} </td>
                  </tr>
                  <tr>
                    <td>S&P 500 Index</td>
                    <td>{Math.round(avgRet.Market * 12 * 100) / 100}%</td>
                    <td>{Math.round(std.Market * 100)}% </td>
                    <td>${Math.round(totalRet.Market * 10000) / 100} </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row className="mt-3">
              <Col className="row justify-content-md-center">
                <SimpleLine data={result} />
              </Col>
            </Row>
            <Row className="row justify-content-md-center">
              <p id="disclaimer" className="mt-3">
                * This historical simulation is based on historical stock
                performance similar to your holdings.
              </p>
            </Row>
          </Tab>

          <Tab eventKey="holdings" title="Stock Holdings">
            <Row>
              <Table striped bordered hover className="mt-4" size="sm">
                <thead>
                  <tr>
                    <th>Ticker</th>
                    <th>Market Value</th>
                  </tr>
                </thead>
                <tbody>
                  {assets.map((a) => (
                    <tr>
                      <td>
                        <ShowTicker ticker={a.ticker.toUpperCase()} />
                      </td>
                      <td>{a.mktval}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </Tab>
        </Tabs>
      </Container>
    );
}
