import React from "react";

export default function ShowTicker(props) {
  return (
    <a
      href={`https://finance.yahoo.com/quote/${props.ticker}?p=${props.ticker}&.tsrc=fin-srch`}
      target="_blank"
    >
      {props.ticker}
    </a>
  );
}
