import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Alert, Button } from "react-bootstrap";
import ResultFooter from "./ResultFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import ResultTable from "./ResultTable";

const convertShares = (fracShares, investAmt) => {
  const roundShare = (asset) => {
    // function to decide whether to round up/round down shares
    // check how many shares can be allocated at most
    const sharePrice = asset.value / asset.shares;
    const maxShares = Math.floor(investAmtLeft / sharePrice);
    if (maxShares > asset.shares) {
      // max number of shares is greater than the fractional share numbers
      {
        return {
          ticker: asset.ticker,
          portpct:
            (Math.max(Math.round(asset.shares), 1) * sharePrice) / investAmt,
          value: Math.max(Math.round(asset.shares), 1) * sharePrice,
          shares: Math.max(Math.round(asset.shares), 1),
        };
      }
    } else {
      // if not, then allocate the max number of shares
      return {
        ticker: asset.ticker,
        portpct: (maxShares * sharePrice) / investAmt,
        value: maxShares * sharePrice,
        shares: maxShares,
      };
    }
  };

  let investAmtLeft = investAmt;

  const sortedFracShares = fracShares
    .slice()
    .sort((a, b) => b["value"] - a["value"]); // descending sort shares based on dollar amount

  const wholeNumberShares = sortedFracShares.map((asset) => {
    const newRoundShare = roundShare(asset);
    investAmtLeft = investAmtLeft - newRoundShare["value"]; // need this to keep track of number of shares left
    return newRoundShare;
  });

  return wholeNumberShares;
};

export default function FormPortResult({
  myAllocation,
  setTopNStocks,
  topNStocks,
  handleNext,
  maxVal,
  setEmail,
  handleSaveGuru,
  emailStatus,
  investAmt,
}) {
  const [showAlert, setShowAlert] = useState(true);
  const [showWholeShares, setShowWholeShares] = useState(false);
  const [wholeShares, setWholeShares] = useState(null);

  useEffect(() => {
    setWholeShares(() => {
      return convertShares(myAllocation, investAmt).filter(
        (asset) => asset.shares > 0.01
      );
    });

    return () => {};
  }, [myAllocation]);

  return (
    <div>
      <Row className="justify-content-center bg-light">
        {showAlert && (
          <Alert className="ml-2 mt-3 mr-2" variant="secondary" body={true}>
            Adjust number of stocks in your portfolio using the slider{" "}
            <FontAwesomeIcon icon={faArrowAltCircleDown} />{" "}
            <Button
              variant="light"
              size="sm"
              onClick={() => setShowAlert(false)}
            >
              Dismiss
            </Button>
          </Alert>
        )}
      </Row>
      <Row className="justify-content-center bg-light">
        <Col lg="8">
          {
            <ResultTable
              myAllocation={showWholeShares ? wholeShares : myAllocation}
              showWholeShares={showWholeShares}
            />
          }
        </Col>
      </Row>

      <Row className="justify-content-center mt-4">
        <Button
          variant="outline-dark"
          onClick={() => setShowWholeShares(!showWholeShares)}
        >
          {showWholeShares
            ? "Show Fractional Shares"
            : "Can't Buy Fractional Shares?"}
        </Button>
      </Row>

      <Row className="justify-content-center">
        <Card className="mt-5 ml-2 mr-2 bg-light text-light text-center">
          <Card.Header className="bg-secondary">
            <h5>Receive Updates </h5>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Label className="text-dark">
                You will receive an email when your gurus' holding changes
              </Form.Label>
              <Form.Control
                onChange={(e) => setEmail(e.currentTarget.value)}
                placeHolder="Email"
              ></Form.Control>
            </Form>
          </Card.Body>
          <Card.Footer>
            {emailStatus === "Success" && (
              <Alert variant="success"> Done! Keep an eye on your inbox.</Alert>
            )}
            {emailStatus === "Fail" && (
              <Alert variant="danger"> Please try again.</Alert>
            )}
            <Button variant="dark" onClick={handleSaveGuru}>
              Submit
            </Button>
          </Card.Footer>
        </Card>
      </Row>
      <ResultFooter
        handleNext={handleNext}
        topNStocks={topNStocks}
        setTopNStocks={setTopNStocks}
        maxVal={maxVal}
      />
    </div>
  );
}
