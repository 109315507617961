import React, {useContext} from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import SimplieBar1 from "./common/SimpleBar1";
import { industries, industryDict } from "../constants";
import { StockFormContext } from './context/AllContext'

export default function IndustryChart({ portchar, guruPortchar, guru }) {
  //console.log(guruPortchar, portchar)
  var indData = industries.map((i) => ({
    name: industryDict[i],
    yp: Math.floor(portchar[i] * 10000) / 100 || 0,
    gp: Math.floor(guruPortchar[i] * 10000) / 100 || 0,
  }));
  indData = indData.filter((i) => i.yp > 0 || i.gp > 0);
  indData = indData.sort((a, b) => (b.yp - a.yp) * 10 - (a.gp - b.gp));
  //console.log(indData);

  const {
    result,
    setResult,
    setShowExplorer,
    setEmail,
    getReport,
    message,
    thisAddress,
    setThisAddress,
  } = useContext(StockFormContext);
  return (
    <Row className="mt-3">
      <Col>
        <Card bg="secondary" text="light" className="border-0 mb-3">
          <Card.Title className="mt-2 ml-2">
            Industry Allocation Comparison
          </Card.Title>

          <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-dark">
            <Row>
              <Col>
                {" "}
                <Card className="border-0 mt-3 ml-3 mr-3 bg-light text-secondary">
                  <Card.Title className="mt-3 ml-3 mr-3">
                    <h4>Your industry similarity to {guru.guru && guru.guru.name}'s:</h4>
                  </Card.Title>
                  <Card.Body>
                    {" "}
                    <h2>
                      {guru.indSim &&
                        (10 - (guru.indSim * 10) / Math.sqrt(2)).toFixed(2)}
                      /10
                    </h2>
                    <Button
                      variant="secondary"
                      className="mt-5"
                      onClick={() => setShowExplorer(true)}
                    >
                      Explore Stocks by Industry
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <SimplieBar1 data={indData} domain={[0, 100]} y="Percentage" />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
