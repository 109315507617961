export const endpoint = "https://us-central1-portdoc.cloudfunctions.net/api";

//export const endpoint = "http://localhost:5000/portdoc/us-central1/api"

export const industries = [
  "ND",
  "DUR",
  "MANU",
  "ENRG",
  "CHEM",
  "TECH",
  "TEL",
  "UTIL",
  "SHOP",
  "HLTH",
  "FIN",
  "O",
];

export const industryDict = {
  ND: "Non Durables",
  DUR: "Durables",
  MANU: "Manufacturing",
  ENRG: "Energy",
  CHEM: "Chemical",
  TECH: "Technology",
  TEL: "Telecom",
  UTIL: "Utilities",
  SHOP: "Retail",
  HLTH: "Healthcare",
  FIN: "Financial",
  O: "Other",
};

export const stockStyle = ["Value", "Momentum", "Profit", "Size"];

export const styleTips = {
  Size: ["small firms, which tend to be riskier", "large firms, which tend to offer more stable returns"],
  Value: ["firms with high valuation, betting on their high growth", "firms with low valuation, betting their prices to rebound"],
  Momentum: ["firms with low recent returns, betting on trend to revert", "firms with high recent returns, betting on trend to continue"],
  Profit: ["firms with low profits recently, betting on profitability to improve", "firms with high profits recently, betting on profits to grow"],
}
