import React from "react";
import { Modal, Card, Button } from "react-bootstrap";

export default function IndSuggest({
  indBoost,
  getIndComment,
  modalIndShow,
  setModalIndShow,
}) {
  return (
    <Modal
      size="lg"
      show={modalIndShow}
      onHide={() => setModalIndShow(false)}
      aria-labelledby="modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-sizes-title-lg">
          Possible Industries to Improve Industry Diversification
        </Modal.Title>
      </Modal.Header>

      <Card bg="light" text="light" className="mt-2 border-0">
        <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-dark">
          <span className="mt-2">{getIndComment(indBoost)}</span>
        </Card.Body>
      </Card>
    </Modal>
  );
}
