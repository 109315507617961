import React from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { industryDict, styleTips } from "../constants";

// provide explanation to industry + style
// potentially provide a list of stocks

const industryExplain = {
  ND: ["Main products: food, clothing, toy ..."],
  DUR: ["Main products: TV, fridge, car ..."],
  MANU: ["Main products: heavy machinery, planes ..."],
  ENRG: ["Main products: oil, gas, coal ..."],
  CHEM: ["Main products: industrial chemical products ..."],
  TECH: ["Main products: computers, software, other high tech products ..."],
  TEL: ["Main services: telecommunication, wireless, Internet ..."],
  UTIL: ["Main services: power, water, ..."],
  SHOP: ["Main services: retail products, costco, repair"],
  HLTH: ["Main products/services: healthcare, drugs"],
  FIN: ["Main services: banks, financing, credit cards"],
  O: ["Remaining economy"],
};

const stockStyleExplain = {
  Value: ["", "", ""],
  Momentum: ["", ""],
  Profit: [""],
  Size: [""],
};
const styleReturn = {
  Value: {
    high: { 1990: 14.7, 2000: 13.2, 2010: 9.4, 2015: 10.7 },
    low: { 1990: 9.7, 2000: 7.4, 2010: 11.0, 2015: 12.5 },
  },
  Profit: {
    high: { 1990: 15.9, 2000: 14.5, 2010: 13.2, 2015: 14.4 },
    low: { 1990: 9.2, 2000: 5.6, 2010: 8.2, 2015: 6.1 },
  },
  Momentum: {
    high: { 1990: 18.1, 2000: 13.2, 2010: 11.9, 2015: 11.6 },
    low: { 1990: 2.5, 2000: 4.4, 2010: -0.1, 2015: 4.7 },
  },
  Size: {
    high: { 1990: 8.1, 2000: 9.9, 2010: 11.9, 2015: 13.1 },
    low: { 1990: 12.2, 2000: 6.4, 2010: 2.2, 2015: -4.1 },
  },
};

export default function ExplainStyle(props) {
  const history = useHistory();
  const getReturnTab = (s) => {
    //console.log(s);
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Style Tilt</th> <th>Since 1990</th> <th>Since 2000</th>{" "}
            <th>Since 2010</th> <th>Since 2015</th>
          </tr>
        </thead>
        <tr>
          <td>High {s}</td> <td>{styleReturn[s]["high"][1990]}% </td>{" "}
          <td>{styleReturn[s]["high"][2000]}% </td>{" "}
          <td>{styleReturn[s]["high"][2010]}% </td>{" "}
          <td>{styleReturn[s]["high"][2015]}% </td>
        </tr>
        <tr>
          <td>Low {s}</td> <td>{styleReturn[s]["low"][1990]}% </td>{" "}
          <td>{styleReturn[s]["low"][2000]}% </td>{" "}
          <td>{styleReturn[s]["low"][2010]}% </td>{" "}
          <td>{styleReturn[s]["low"][2015]}% </td>
        </tr>
      </Table>
    );
  };

  return (
    <div>
      <Modal
        size="lg"
        show={props.modalShow}
        onHide={() => props.setModalShow(false)}
        aria-labelledby="modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-sizes-title-lg">
            Facts about{" "}
            {industryDict[props.s]
              ? industryDict[props.s] + " industry"
              : props.s + " style"}
          </Modal.Title>
        </Modal.Header>
        {styleTips[props.s] ? (
          <Modal.Body>
            <p>
              <ul>
                <li>
                  Low {props.s} Stocks: These are {styleTips[props.s][0]}
                </li>
                <li>
                  High {props.s} Stocks: These are {styleTips[props.s][1]}
                </li>
              </ul>
            </p>
            <h5 className="mt-5">
              {" "}
              Average annual returns for stocks with high/low {props.s}
            </h5>
            {getReturnTab(props.s)}
            <p className="mt-5">
              <h5>Explore stocks by {props.s} style (1 = low, 5 = high)</h5>
              <span STYLE="font-size:18.0pt">{props.s}= </span>
              {[1, 2, 3, 4, 5].map((i) => (
                <Button
                  variant="dark"
                  className="ml-1"
                  onClick={() => {
                    props.setShowExplorer({
                      query: props.s,
                      queryValue: i,
                    });
                  }}
                >
                  {i}
                </Button>
              ))}
            </p>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <p>
              <ul>
                {industryExplain[props.s].map((style) => (
                  <li>{style}</li>
                ))}
              </ul>
            </p>
            <p>
              {" "}
              <Button
                variant="dark"
                onClick={() => {
                  props.setShowExplorer({
                    query: "industry",
                    queryValue: props.s,
                  });
                }}
              >
                Explore stocks in this industry{" "}
              </Button>{" "}
            </p>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
}
