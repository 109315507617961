import React from "react";
import { Table, Row, Container } from "react-bootstrap";

export default function GuruPerformanceTable({ guru }) {
  const getTextColor = (ret) => {
    if (ret > 0) {
      return "text-success";
    } else return "text-danger";
  };
  return (
    <Container className="ml-1 mr-2 mt-4 border-top border-dark">
      <Container className="mt-3">
        <Row className="">
          <h5>Holding Performance</h5>
        </Row>
        <Row className="">
          <Table size="sm" borderless={true}>
            <thead>
              <tr>
                <th>This Year</th>
                <th>One Year</th>
                <th>Three Years</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={getTextColor(guru.ytd)}>
                  <b>{guru.ytd}%</b>
                </td>

                <td className={getTextColor(guru["1y"])}>
                  <b>{guru["1y"]}%</b>
                </td>

                <td className={getTextColor(guru["3y"])}>
                  <b>{guru["3y"]}%</b>
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </Container>
    </Container>
  );
}
