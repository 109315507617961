import React from "react";
import { industryDict, stockStyle } from "../constants";
import ShowTicker from "./common/ShowTicker";
import { Table, Container, Button } from "react-bootstrap";

export default function DisplayHoldings({
  setModalShow,
  setModalSubject,
  assetsResult,
}) {
  return (
    <Container>
      <Table striped bordered hover className="mt-4" size="sm">
        <thead>
          <tr>
            <th>Ticker</th> <th>Shares</th> <th>Industry</th> 
            {stockStyle.map((s) => (
              <th>{s}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {assetsResult.map((a) => (
            <tr>
              <td>
                <ShowTicker ticker={a.ticker.toUpperCase()} />
              </td>
              <td>
                {a.shares.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td>
                <Button
                  className="mt-1"
                  variant="light"
                  onClick={() => {
                    setModalShow(true);
                    setModalSubject(a.industry);
                  }}
                  active
                >
                  {industryDict[a.industry]}
                </Button>
              </td>
              {stockStyle.map((s) => (
                <td>
                  <Button
                    className="mt-1"
                    variant="light"
                    onClick={() => {
                      setModalShow(true);
                      setModalSubject(s);
                    }}
                    active
                  >
                    {a[s.toLowerCase()]}
                  </Button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
