import React, { useState, useEffect } from "react";
import { MyResponsivePie } from "../common/NivoPie";
import { Row } from "react-bootstrap";
import Spinner from "../common/MySpinner";
// present a portfolio allocation of gurus before showing stocks allocations
export default function WaitForPort({ guruPct, allGurus }) {
  const [plotData, setPlotData] = useState(null);

  //console.log(guruPct, allGurus);
  useEffect(() => {
    // form the data for pie chart
    const newPlotData = guruPct
      .map((p, i) => ({
        value: p,
        id: allGurus[i].name,
        label: allGurus[i].name,
      }))
      .filter((p) => p.value > 0);
    //console.log(newPlotData);
    setPlotData(newPlotData);
    return () => {};
  }, [guruPct]);

  return (
    <div>
      <Row className="justify-content-center mt-3">
        {" "}
        <h4>Your investment allocation</h4>
      </Row>
      <div
        style={{
          width: "100%",
          height: "45vh",
          float: "left",
          overflowY: "auto",
        }}
      >
        {plotData && <MyResponsivePie data={plotData} />}
      </div>
      <Row className="mt-2 mt-md-4">
        <Spinner text="Customizing your portfolio" />
      </Row>
    </div>
  );
}
