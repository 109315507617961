import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Alert,
  Card,
  Modal,
} from "react-bootstrap";

import axios from "axios";
import SinglePageResult from "./SinglePageResult";
import { endpoint } from "../constants";
import StockExplorer from "./StockExplorer";
import MySpinner from "./common/MySpinner";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import { StockFormContext } from "./context/AllContext";
import PortfolioForm from "./PortfolioForm";

export default function StockForm(props) {
  // recording the number of stocks
  const [numStocks, setNumStocks] = useState(3);
  // state recording the total portfolio value
  // const [portValue, setPortValue] = useState(0);
  // recording the results
  const [result, setResult] = useState(null);
  const [showExplorer, setShowExplorer] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  var p = useParams();
  var address;
  const [thisAddress, setThisAddress] = useState("");
  try {
    address = p.address;
  } catch {
    address = "";
  }

  // state of the list of stocks under consideration
  const [portfolio, setPortfolio] = useState([
    { assetId: 0, ticker: "", shares: 0 },
    { assetId: 1, ticker: "", shares: 0 },
    { assetId: 2, ticker: "", shares: 0 },
  ]);

  useEffect(() => {
    const headers = {
      "Access-Control-Request-Headers": "Content-Type",
      "Content-Type": "application/json",
    };

    axios.get(endpoint + "/getcomjson", headers).then((res) => {
      setCompanies(res.data);
      //console.log(res.data)
    });
    return () => {};
  }, []);

  useEffect(() => {
    // if a parameter is passed in, we'll just go there and try to retrieve the result

    const headers = {
      "Access-Control-Request-Headers": "Content-Type",
      "Content-Type": "application/json",
    };
    if (address !== "" && address !== null) {
      setThisAddress(`https://portdoc.web.app/${address}`);
      // download Portfolio information from the api and save them as results
      axios
        .post(`${endpoint}/readport`, { address: address }, headers)
        .then((res) => {
          setResult(res.data.result);
          //console.log(res.data.result)
        })
        .catch(() => {
          // if cannot retrieve the address, then set to null
          setThisAddress("");
          setResult(null);
        });
    }
    return () => {};
  }, []);

  const getShares = (shares) => {
    if (shares !== 0) return shares;
    else return "";
  };

  const addAsset = () => {
    setNumStocks(numStocks + 1);
    setPortfolio([...portfolio, { assetId: numStocks, ticker: "", shares: 0 }]);
  };

  const setAssetTicker = (index, assetId, ticker) => {
    //console.log(index,assetId,ticker)

    const newPortfolio = [...portfolio];
    const shares = newPortfolio[index]["shares"];
    
    newPortfolio[index] = { assetId, ticker: ticker.toUpperCase(), shares };
    setPortfolio(newPortfolio);
  };

  const setAssetShares = (index, assetId, shares) => {
    //console.log(shares);
    const newPortfolio = [...portfolio];
    const ticker = newPortfolio[index]["ticker"];
    newPortfolio[index] = { assetId: assetId, ticker: ticker, shares: shares };
    //console.log(portfolio);
    setPortfolio(newPortfolio);
  };

  // delete an asset
  const deleteAsset = (assetId) => {
    const newPortfolio = portfolio.filter(
      (asset) => asset["assetId"] !== assetId
    );
    setPortfolio(newPortfolio);
  };

  // submit for analyses

  const handleSubmit = () => {
    setShowSpinner(true);
    const headers = { "Content-Type": "application/json" };
    //console.log("submit", portfolio);
    axios.post(endpoint, portfolio, headers).then((res) => {
      //console.log(res);
      setShowSpinner(false);

      if (
        res["data"] === undefined ||
        res["data"].portfolio.mktval === 0 ||
        res["data"].portfolio.nAssets === 0
      ) {
        setErrorMsg('Please check shares and tickers.')
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 10000);
      } else {
        setResult(res["data"]);
        localStorage.setItem("portf", JSON.stringify(portfolio));
        localStorage.setItem("numStocks", JSON.stringify(numStocks));
        // save portfolio to local storage
        //console.log(res["data"])
      }
    });
  };

  const loadPortf = () => {
    try {
      var storedPortf = JSON.parse(localStorage.getItem("portf"));
      if (storedPortf) {
        setPortfolio(storedPortf);
        setNumStocks(parseInt(localStorage.getItem("numStocks")));
        // console.log(storedPortf);
        // console.log(portfolio);
      } else {
        setErrorMsg('Cannot load portfolio information. Last portfolio information cleared. ')
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 10000);
      }
    } catch {
      console.log("cannot find");
    }
  };

  const reset = () => {
    setPortfolio([
      { assetId: 0, ticker: "", shares: 0 },
      { assetId: 1, ticker: "", shares: 0 },
      { assetId: 2, ticker: "", shares: 0 },
    ]);
    setNumStocks(3);
    localStorage.clear();
  };

  const getReport = () => {
    // check email
    //console.log({ email: email, assets: result.assets })
    //console.log({ email: email, assets: portfolio })
    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    if (validateEmail(email)) {
      const headers = { "Content-Type": "application/json" };
      axios
        .post(
          endpoint + "/getreport",
          { email: email, assets: result.assets },
          headers
        )
        .then((res) => {
          if (res["data"] === "Success") {
            setMessage("Received and Thank you!");
          } else setMessage("Error");
        });
    } else setMessage("Email address is invalid.");
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  let render;

  if (showSpinner) {
    return <MySpinner text="Loading Data" />;
  } else if (showExplorer)
    render = (
      <StockExplorer
        setShowExplorer={setShowExplorer}
        showExplorer={showExplorer}
      />
    );
  else {
    if (result === null) {
      render = (
        <>
          <Container fluid>
            <Modal
              show={showDisclaimer}
              onHide={() => setShowDisclaimer(false)}
              aria-labelledby="modal-sizes-title-lg"
            >
              <Modal.Title className="ml-2 mt-2">Disclaimer</Modal.Title>
              <Modal.Body>
                <h5> NO INVESTMENT ADVICE</h5>
                The Content is for informational purposes only, you should not
                construe any such information or other material as legal, tax,
                investment, financial, or other advice. Nothing contained on our
                Site constitutes a solicitation, recommendation, endorsement, or
                offer by this site or any third party service provider to buy or
                sell any securities or other financial instruments in this or in
                in any other jurisdiction in which such solicitation or offer
                would be unlawful under the securities laws of such
                jurisdiction. All Content on this site is information of a
                general nature and does not address the circumstances of any
                particular individual or entity. Nothing in the Site constitutes
                professional and/or financial advice, nor does any information
                on the Site constitute a comprehensive or complete statement of
                the matters discussed or the law relating thereto. This site is
                not a fiduciary by virtue of any person’s use of or access to
                the Site or Content. You alone assume the sole responsibility of
                evaluating the merits and risks associated with the use of any
                information or other Content on the Site before making any
                decisions based on such information or other Content. In
                exchange for using the Site, you agree not to hold this site,
                its affiliates or any third party service provider liable for
                any possible claim for damages arising from any decision you
                make based on information or other Content made available to you
                through the Site.
                <h5>INVESTMENT RISKS</h5>
                There are risks associated with investing in securities.
                Investing in stocks, bonds, exchange traded funds, mutual funds,
                and money market funds involve risk of loss. Loss of principal
                is possible. Some high risk investments may use leverage, which
                will accentuate gains & losses. Foreign investing involves
                special risks, including a greater volatility and political,
                economic and currency risks and differences in accounting
                methods. A security’s or a firm’s past investment performance is
                not a guarantee or predictor of future investment performance.
              </Modal.Body>
            </Modal>
            <Row>
              <Col>
                <Row className="justify-content-md-center mt-4">
                  {showAlert && (
                    <Alert
                      variant="danger"
                      onClose={() => setShowAlert(false)}
                      dismissible
                    >
                      <Alert.Heading>Error</Alert.Heading>
                      <p>{errorMsg}</p>
                    </Alert>
                  )}
                </Row>
                <Row className="justify-content-md-center mt-4">
                  <PortfolioForm
                    handleSubmit={handleSubmit}
                    portfolio={portfolio}
                    setAssetTicker={setAssetTicker}
                    companies={companies}
                    getShares={getShares}
                    deleteAsset={deleteAsset}
                    addAsset={addAsset}
                    setAssetShares={setAssetShares}
                    loadPortf={loadPortf}
                    reset={reset}
                  />
                </Row>
              </Col>
            </Row>
          </Container>
          <Footer setShowDisclaimer={setShowDisclaimer} />
        </>
      );
    } else
      render = (
        <StockFormContext.Provider
          value={{
            result,
            setResult,
            setShowExplorer,
            setEmail,
            getReport,
            message,
            thisAddress,
            setThisAddress,
          }}
        >
          <SinglePageResult />
          
        </StockFormContext.Provider>
      );
  }
  return render;
}
