import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label,
  LabelList,
} from "recharts";

export default class SimpleBar1 extends PureComponent {
  render() {
    return (
      <BarChart
        width={450}
        height={500}
        barSize={50}
        maxBarSize={5}
        layout="vertical"
        data={this.props.data}
        tickLine={false}
        margin={{
          top: 5,
          right: 10,
          left: 40,
          bottom: 10,
        }}
      >
        <YAxis dataKey="name" type="category" />
        <XAxis
          type="number"
          domain={[0, 100]}
          label={{
            value: "Industry allocation in %",
            angle: 0,
            position: "top",
          }}
        />
        <CartesianGrid strokeDasharray="3 3" />

        <Tooltip />
        <Bar name="Your Portfolio" dataKey="yp" fill="#d9d90f" />
        <Bar name="Guru Portfolio" dataKey="gp" fill="#0fc1d9"></Bar>
        <LabelList dataKey="name" fill="#FFFFFF" angle="45" />
        <Legend />
      </BarChart>
    );
  }
}
