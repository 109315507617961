import "./App.css";
import React from "react";
import { Route, Switch } from "react-router-dom";
import "font-awesome/css/font-awesome.css";

import "bootstrap/dist/css/bootstrap.css";
//import { Container } from "react-bootstrap";
import NavBar from "./components/NavBar";

import StockForm from "./components/StockForm";
import StockExplorer from "./components/StockExplorer";
import ReadReport from "./components/ReadReport";
import FormPort from "./components/FormPort";
import SEO from "./SEO";
import fire from "./firebase";

fire.analytics();

function App() {
  return (
    <div>
      <SEO />
      <NavBar />
      <Switch>
        <Route path="/" exact component={FormPort} />
        <Route path="/formport" exact component={FormPort} />
        <Route path="/stockexplorer" exact component={StockExplorer} />
        <Route exact path="/report" exact component={StockForm} />
        <Route exact path="/readreport/:address" component={ReadReport} />
        <Route exact path="/:address" component={StockForm} />
      </Switch>
    </div>
  );
}

export default App;
