import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyB7jO6GBhAKSen7qaKXPtiZt4jc5GCPH7A",
  authDomain: "portdoc.firebaseapp.com",
  projectId: "portdoc",
  storageBucket: "portdoc.appspot.com",
  messagingSenderId: "908764668084",
  appId: "1:908764668084:web:102387e06433404bfaf7e1",
  measurementId: "G-8Y42WPP0T7",
};

const fire = firebase.initializeApp(firebaseConfig);

export default fire;
