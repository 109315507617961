import React, { useState } from "react";
import { Form, Row, Col, Button, Modal, Container } from "react-bootstrap";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

export default function NStocksSlider({ topNStocks, setTopNStocks, maxVal }) {
  const [showMessage, setShowMessage] = useState(false);

  return (
    <Container>
      <Modal
        show={showMessage}
        onHide={() => setShowMessage(!showMessage)}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {topNStocks >= 5 ? (
            <b className="text-success">
              You are considering a diversified portfolio. Usually, they have
              lower risks.
            </b>
          ) : (
            <b className="text-danger">
              Holding few stocks can increase your risk level.
            </b>
          )}
        </Modal.Body>
        <Modal.Footer>
          Use the slider to select the number of stocks in your portfolio.
        </Modal.Footer>
      </Modal>

      <Row>
        <Col>
          <Button
            variant={topNStocks < 5 ? "outline-danger" : "outline-primary"}
            onClick={() => {
              setShowMessage(true);
            }}
          >
            <b>
              {topNStocks} stocks in the portfolio{" "}
              {topNStocks < 5 ? (
                <span className="text-danger">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </span>
              ) : (
                <span className="text-primary">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
              )}
            </b>
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6} sm={7} xs={10}>
          <RangeSlider
            min={1}
            size="sm"
            max={maxVal}
            tooltipPlacement="top"
            value={topNStocks}
            onChange={(e) => {
              setTopNStocks(e.currentTarget.value);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}
