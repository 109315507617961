import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";

import axios from "axios";
import { endpoint } from "../constants";

import InvestAmtForm from "./FormPort/InvestAmtForm";
import GuruForm from "./FormPort/GuruForm";
import FormPortResult from "./FormPort/FormPortResult";
import Spinner from "./common/MySpinner";
import WaitForPort from "./FormPort/WaitForPort";

import "../style.css";

export default function FormPort() {
  const [allGurus, setAllGurus] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [portAmt, setPortAmt] = useState(0); // portAmt stores the total dollar amount of portfolio to be allocated
  const [guruPct, setGuruPct] = useState([]); // guruPct is an array that stores the percentage
  const [guruPctString, setGuruPctString] = useState([]); // save the input as a string -- this is to be passed to the display
  const [pctToBeAllocated, setPctToBeAllocated] = useState(100);
  const [fullAllocation, setFullAllocation] = useState([]); // full allocation reflects allocation for all stocks
  const [myAllocation, setMyAllocation] = useState([]); // my allocation adjusts for the adjustments of the top N stocks
  const [topNStocks, setTopNStocks] = useState(10); // we only allocated based on top N stocks
  const [screen, setScreen] = useState(1); // screen in (1,2,3) to control which screen to display
  const [alertMsg, setAlertMsg] = useState(""); // record any alert message
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState("");

  const resetGuruPct = () => {
    const newGuruPct = allGurus.map((g) => 0);
    const newGuruPctString = allGurus.map((g) => "");
    setGuruPct(newGuruPct);
    setGuruPctString(newGuruPctString);
    setPctToBeAllocated(100);
  };

  useEffect(() => {
    // fetch guru data
    const headers = { "Content-Type": "application/json" };
    //console.log("submit", portfolio);
    // data should have two components:
    setShowSpinner(true);
    axios
      .get(endpoint + "/getgurujson", headers)
      .then((res) => {
        return res.data;
      })
      .then((guruPort) => {
        // get all gurus
        setAllGurus(guruPort);
        setShowSpinner(false);
      });

    return () => {};
  }, []);

  useEffect(() => {
    // Re-initialize guruPct array when guruPort changes
    if (allGurus.length > 0) {
      resetGuruPct();
    }

    return () => {};
  }, [allGurus]);

  useEffect(() => {
    // when top N, or the set of stocks , recalculate portfolios for my specific allocation
    // take the full allocation array, select those the top N percentage
    var tempAllocation = fullAllocation
      .sort((asset1, asset2) => asset2.portpct - asset1.portpct)
      .slice(0, topNStocks);

    const topStockPct = tempAllocation.reduce(
      (preVal, asset) => preVal + asset.portpct,
      0
    );

    const newMyAllocation = tempAllocation.map((asset) => ({
      ticker: asset.ticker,
      portpct: asset.portpct / topStockPct,
      value: asset.value / topStockPct,
      shares: asset.shares / topStockPct,
    }));

    setMyAllocation(newMyAllocation);
    return () => {};
  }, [topNStocks, fullAllocation]);

  const handleChangeAllocation = (i, value) => {
    // when allocation changes, first change the value of the corresponding cell
    // also change the overall allocation state

    try {
      let newGuruPct = [...guruPct];
      let newGuruPctString = [...guruPctString];

      newGuruPctString[i] = `${value}`;

      setGuruPctString(newGuruPctString);

      if (value.trim().length === 0) {
        setPctToBeAllocated(pctToBeAllocated + guruPct[i]);
        newGuruPct[i] = 0;
      } else {
        const newPct = parseFloat(value);
        // console.log(pctToBeAllocated - (newPct - guruPct[i]));
        if (newPct >= 0) {
          const newPctToBeAllocated = pctToBeAllocated - (newPct - guruPct[i]);
          setPctToBeAllocated(newPctToBeAllocated);
          newGuruPct[i] = newPct; // valid observation, update the value
          // update the PctToBeAllocated;;
        } else {
          // invalid observation, send an alert and do nothing
        }
      }
      setGuruPct(newGuruPct);
    } catch {
      console.error("error in value");
      // if the value is not correct, then just skip and return
      return;
    }
  };

  const handleSaveGuru = () => {
    // new array to combine guru
    console.log(allGurus);
    const toBeUploaded = guruPct.map((g, i) => ({
      guru: allGurus[i].name,
      pct: g,
    }));
    console.log(toBeUploaded);
    const headers = { "Content-Type": "application/json" };

    axios
      .post(
        endpoint + "/postguru",
        { email: email, guruAllocation: toBeUploaded },
        headers
      )
      .then((res) => {
        console.log(res.status);
        if (res.status === 200) {
          setEmailStatus("Success");
        } else {
          setEmailStatus("Fail");
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
        setEmailStatus("Fail");
      });
  };

  const handleNext = (from, inc) => {
    // from = from which screen
    // inc controls direction, 1 is forward, other goes backward
    const clearAlert = () => {
      setTimeout(() => {
        setAlertMsg("");
      }, 3500);
    };
    if (from === 1) {
      if (portAmt > 0) {
        setScreen(2);
        setAlertMsg("");
      } else {
        // set alert
        setAlertMsg("Please enter a positive dollar amount!");
        clearAlert();
      }
    }
    if (from === 2) {
      if (inc === 1) {
        if (Math.abs(pctToBeAllocated) < 0.009) {
          handleAllocatePort();
          setScreen(3);
          setAlertMsg("");
        } else {
          // set alert
          if (pctToBeAllocated > 0) {
            setAlertMsg("Please allocate 100% of your portfolio.");
            clearAlert();
          } else setAlertMsg("Please do not allocate more than 100%.");
          clearAlert();
        }
      } else {
        setScreen(1);
        setAlertMsg("");
      }
    }

    if (from === 3) {
      setScreen(2);
      setAlertMsg("");
      setTopNStocks(10); // reset topNStock Number
    }
  };

  const handleAllocatePort = () => {
    // function that handles portfolio allocation

    const addGuruToArray = (prevGurus, guru) => {
      // helper function to handle concatenating stocks held by gurus
      // prevGurus is a an object, guru is a guru.assets array
      var newGurus = prevGurus;

      guru.forEach((asset) => {
        // push the element into the map
        if (
          prevGurus[asset.ticker] !== undefined &&
          prevGurus[asset.ticker] !== null
        ) {
          newGurus[asset.ticker] = prevGurus[asset.ticker] + asset.portpct;
        } else {
          if (asset.portpct > 0) newGurus[asset.ticker] = asset.portpct;
        }
      });
      return newGurus; // newGurus is an object newGurus[ticker] = portpct;
    };

    // first check input
    if (portAmt <= 0) {
      // if portfolio amount is non-positive, do nothing

      // need to send an alert message
      console.log("cannot proceed, error in input");
      return;
    }

    if (Math.abs(pctToBeAllocated) > 0.009) {
      // if the portfolio is under 100% or over 100%, we should not proceed
      // send a message later
      return;
    }

    // start allocation analysis

    // 1 - aggregate the $ amount of shares on each ticker based on allocation
    const guruAllocation = allGurus.map((guru, i) => {
      const aum = guru.portchar.mktval;
      const thisGuruPct = guruPct[i];
      const assets = guru.assets.map((asset) => ({
        ticker: asset.ticker,
        portpct: ((asset.mktval / aum) * thisGuruPct) / 100,
      })); // this array contains the percentage of assets in this gurus portfolio x pct of guru
      return assets;
    }); // this is an array of an array, allocation for each ticker for each guru

    const myAllocation = guruAllocation.reduce(addGuruToArray, []);
    // transform myAllocation to value pairs
    const tickerValue = Object.keys(myAllocation).map((key) => ({
      ticker: key,
      portpct: myAllocation[key],
      value: myAllocation[key] * portAmt, // dollar amount in portfolio
    }));

    const headers = { "Content-Type": "application/json" };
    setShowSpinner(true);
    var time1 = new Date();
    axios
      .post(endpoint + "/getshares", tickerValue, headers)
      .then((res) => {
        setFullAllocation(res.data);
        setTopNStocks(res.data.length > 10 ? 10 : res.data.length);
        var time2 = new Date();

        // wait at least 2.5 seconds before turning off spinner
        if (time2 - time1 > 4500) {
          setShowSpinner(false);
        } else {
          setTimeout(() => setShowSpinner(false), 3500);
        }
        return null;
      })
      .catch((err) => console.log(err));

    // now sent this request to get number of shares
    // console.log("My allocation", myAllocation);

    // 2 - get the # of shares for each ticker
  };

  const handleMixUp = () => {
    // generate a randomized portfolio based on gurus

    const randomWeightRaw = allGurus.map((guru) => Math.random());

    const normalWeight = randomWeightRaw.reduce((a, b) => a + b, 0);
    const newGuruPct = randomWeightRaw.map(
      (w) => Math.floor((w / normalWeight) * 10000) / 100
    );

    setGuruPct(newGuruPct);
    const newGuruPctString = newGuruPct.map((pct) => pct.toFixed(2));
    setGuruPctString(newGuruPctString);
    setPctToBeAllocated(0);
  };

  const handleReset = () => {
    resetGuruPct();
  };

  let render;

  // in screen 1, show investment amount, and not wait for spinner
  if (screen === 1) {
    render = (
      <>
        <InvestAmtForm
          setPortAmt={setPortAmt}
          portAmt={portAmt}
          handleNext={handleNext}
          alertMsg={alertMsg}
        />
      </>
    );
  }

  // for screens 2 and 3, we need to use spinner for better loading effects
  if (!showSpinner || screen === 1) {
    if (screen === 2) {
      render = (
        <GuruForm
          allGurus={allGurus}
          pctToBeAllocated={pctToBeAllocated}
          handleChangeAllocation={handleChangeAllocation}
          handleNext={handleNext}
          guruPctString={guruPctString}
          handleMixUp={handleMixUp}
          handleReset={handleReset}
          alertMsg={alertMsg}
        />
      );
    }

    if (screen === 3) {
      render = (
        <FormPortResult
          myAllocation={myAllocation}
          maxVal={fullAllocation.length}
          handleAllocatePort={handleAllocatePort}
          topNStocks={topNStocks}
          setTopNStocks={setTopNStocks}
          handleNext={handleNext}
          setEmail={setEmail}
          handleSaveGuru={handleSaveGuru}
          emailStatus={emailStatus}
          investAmt={portAmt}
        />
      );
    }
  } else {
    if (screen === 3) {
      render = <WaitForPort allGurus={allGurus} guruPct={guruPct} />;
    } else render = <Spinner />;
  }

  return <Container fluid>{render}</Container>;
}
