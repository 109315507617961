import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Form,
  Card,
  Row,
  Col,
  Button,
  Alert,
  Tabs,
  Tab,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import ExplainStyle from "./ExplainStyle.jsx";
import { industries, industryDict, stockStyle } from "../constants";
import MySpinner from "./common/MySpinner.jsx";
import DisplayHoldings from "./DisplayHoldings";
import { customAlphabet } from "nanoid";
import axios from "axios";
import { endpoint, styleTips } from "../constants";
import { StockFormContext } from "./context/AllContext";
import Sharing from "./Sharing";
import IndSuggest from "./IndSuggest";
import DetailReport from "./DetailReport";
import StyleChart from "./StyleChart";
import IndustryChart from "./IndustryChart";
import DivChart from "./DivChart";
//import StockExplorer from "./StockExplorer";
//import Blink from "react-blink-text";

const getGuruSim = async (guruPort, myPort) => {
  // guruPort is an array of guru's portfolio
  // Each observation has the following field: name, description, portchar

  // now iterate through guruPort

  const calcSim = (list, port1) => {
    // Calculate style similarity scores

    const preproc = (p, v) => {
      if (p[v.toUpperCase()] !== undefined) return p[v.toUpperCase()];
      else return p[v.toLowerCase()];
    };
    return Math.pow(
      list.reduce((acc, curr) => {
        return acc + Math.pow(preproc(port1, curr) - preproc(myPort, curr), 2);
      }, 0),
      0.5
    );

    // Calculate industry similarity scores
    // Get a weighted style similarity and industry similarity score
  };

  var guruPortScores = guruPort.map((g) => ({
    guru: g,
    indSim: calcSim(industries, g.portchar),
    styleSim: calcSim(stockStyle, g.portchar),
  }));

  var myGuru = guruPortScores.reduce((acc, curr) => {
    if (
      (curr.indSim * 10) / Math.sqrt(2) + (curr.styleSim / 16) * 40 <
      (acc.indSim * 10) / Math.sqrt(2) + (acc.styleSim / 16) * 40
    )
      return curr;
    else return acc;
  });
  return myGuru;
};

const getInd = async (portResult) => {
  var nInd = 0;
  var indDistTemp = [];
  var indBoostTemp = [];
  industries.forEach((ind) => {
    if (portResult[ind] > 0) nInd += 1;
    if (portResult[ind] < 0.05)
      indBoostTemp.push({
        id: ind,
        name: industryDict[ind],
        value: Math.round(portResult[ind] * 10000) / 100,
      });
    if (portResult[ind] > 0) {
      indDistTemp.push({
        name: industryDict[ind],
        value: Math.round(portResult[ind] * 10000) / 100,
      });
    }
  });

  return { nInd: nInd, indDist: indDistTemp, indBoost: indBoostTemp };
};

export default function SinglePageResult() {
  const {
    result,
    setResult,
    setShowExplorer,
    setEmail,
    getReport,
    message,
    thisAddress,
    setThisAddress,
  } = useContext(StockFormContext);
  // populate all the important variables
  const [portResult, setPortResult] = useState(result.portfolio);
  const [assetsResult, setAssetsResult] = useState(result.assets);
  const [portScore, setPortScore] = useState(0);
  const [portMsg, setPortMsg] = useState("");
  const [divScore, setDivScore] = useState(0);
  const [divMsg, setDivMsg] = useState("");
  const [indDivScore, setIndDivScore] = useState(0);
  const [indDivMsg, setIndDivMsg] = useState("");
  const [indDist, setIndDist] = useState([]);
  const [styles, setStyles] = useState([]);
  const [meanPortRet, setMeanPortRet] = useState(0);
  const [sdPortRet, setSdPortRet] = useState(0);

  const [indBoost, setIndBoost] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalSubject, setModalSubject] = useState("Value");
  const [modalIndShow, setModalIndShow] = useState(false);
  const [resultUploaded, setResultUploaded] = useState(false);
  const [address, setAddress] = useState("");

  const [guru, setGuru] = useState({});
  const [allGurus, setAllGurus] = useState([]);
  const [allowedGurus, setAllowedGurus] = useState([]);
  const [guruStyle, setGuruStyle] = useState([]);
  const [guruIndDist, setGuruIndDist] = useState([]);
  const [guruPortScore, setGuruPortScore] = useState(0);
  const [guruPortMsg, setGuruPortMsg] = useState("");
  const [guruDivScore, setGuruDivScore] = useState(0);
  const [guruDivMsg, setGuruDivMsg] = useState("");
  const [guruIndDivScore, setGuruIndDivScore] = useState(0);
  const [GuruIndDivMsg, setGuruIndDivMsg] = useState("");
  const [key, setKey] = useState("home");
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  window.mobileCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  useEffect(() => {
    setIsMobileOrTablet(window.mobileCheck());
  }, []);

  const handleOnClick = () => {
    checkUpload();
    if (navigator.share) {
      navigator
        .share({
          title: "Portfolio Scope",
          text: `My portfolio is like ${guru.guru.name} `,
          url: thisAddress,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong sharing the blog", error);
        });
    }
  };

  const getStyle = async (portchar) => {
    var styleTemp = [];
    stockStyle.forEach((s) =>
      styleTemp.push({
        name: s,
        value: portchar[s.toLowerCase()],
      })
    );
    return styleTemp;
  };

  const getIndDiv = async (nInd, portResult) => {
    var indDivScore;
    var indDivMsg;
    if (nInd === 1) {
      indDivScore = 1;
      indDivMsg =
        "Holding all assets in one industry leads to volatile returns";
    } else if (portResult.hhiInd > 0.7 || nInd <= 2) {
      indDivScore = 2;
      indDivMsg =
        "Low underdiversified at the industry level. Expect higher volatlity levels in return.";
    } else if (portResult.hhi > 0.55 || nInd <= 4) {
      indDivScore = 3;
      indDivMsg = "Slightly underdiversified on the industry front.";
    } else if (portResult.hhi > 0.35 || nInd <= 6) {
      indDivScore = 4;
      indDivMsg = "Relatively diversified across different industries.";
    } else {
      indDivScore = 5;
      indDivMsg =
        "Well diversified! Usually this corresponds to lower return volatility!";
    }
    return { indDivScore, indDivMsg };
  };

  const getStockDiv = async (portResult) => {
    var divScore;
    var divMsg;

    if (portResult.nAssets === 1) {
      divScore = 1;
      divMsg = "Very underdiversified - likely risky.";
    } else if (portResult.hhi > 0.35 || portResult.nAssets <= 3) {
      divScore = 2;
      divMsg = "Still underdiversified - usually more risky.";
    } else if (portResult.hhi > 0.25 || portResult.nAssets <= 5) {
      divScore = 3;
      divMsg = "This portfolio is slightly underdiversified.";
    } else if (portResult.hhi > 0.18 || portResult.nAssets <= 10) {
      divScore = 4;
      divMsg = "This is a relatively diversified portfolio. ";
    } else {
      divScore = 5;
      divMsg = "Beautifully diversified!";
    }

    return { divScore, divMsg };
  };

  const getPortScore = async (divScore, indDivScore) => {
    //console.log(divScore,indDivScore)
    var score = (divScore + indDivScore) / 2;
    var msg;
    if (score <= 2) {
      msg = "not well diversified";
    } else if (score <= 3.5) {
      msg = "somewhat diversified";
    } else msg = "beautifully diversified";
    return { score, msg };
  };

  const getStyleComment = (styles) => {
    // display the comments for states
    //console.log(styles)
    const getComment = (s, val) => {
      return (
        <>
          {" "}
          {val > 2 && val < 4 ? "tilted toward" : "heavily invest in"}{" "}
          {val >= 3 ? styleTips[s][1] : styleTips[s][0]}{" "}
        </>
      );
    };
    return (
      <>
        <ul>
          {styles.map((s) => (
            <li>
              {" "}
              <Button
                className="mt-1"
                variant="outline-dark"
                onClick={() => {
                  setModalShow(true);
                  setModalSubject(s.name);
                }}
              >
                {s.name} ({s.value.toFixed(1)}/5)
              </Button>{" "}
              {getComment(s.name, s.value)}
            </li>
          ))}
        </ul>
      </>
    );
  };

  useEffect(() => {
    getPortScore(guruIndDivScore, guruDivScore).then((val) => {
      setGuruPortScore(val.score);
      setGuruPortMsg(val.msg);
    });

    getPortScore(divScore, indDivScore).then((val) => {
      setPortScore(val.score);
      setPortMsg(val.msg);
    });

    return () => {};
  }, [divScore, indDivScore, guruDivScore, guruIndDivScore]);

  const getIndComment = (indBoost) => {
    // display the comments for industries
    return (
      <>
        To achieve better diversification, consider increasing allocation to the
        following industries:
        <ul>
          {indBoost.map((ind) => (
            <li>
              <Button
                className="mt-1"
                variant="outline-dark"
                onClick={() => {
                  setModalShow(true);
                  setModalSubject(ind.id);
                }}
              >
                {ind.name}
              </Button>{" "}
              current allocation is {ind.value.toFixed(1)}%
            </li>
          ))}
        </ul>
      </>
    );
  };

  const getIndAllocation = (portResult) => {
    //console.log(portResult);
    return (
      <>
        <span style={{ fontSize: 17 }}></span>
        <ul>
          {industries.map((ind) => {
            if (portResult && portResult[ind] > 0)
              return (
                <li>
                  <Button
                    className="mt-1"
                    variant="outline-dark"
                    onClick={() => {
                      setModalShow(true);
                      setModalSubject(ind);
                    }}
                  >
                    {industryDict[ind]} {(portResult[ind] * 100).toFixed(1)}%
                  </Button>
                </li>
              );
            else return <></>;
          })}
        </ul>
      </>
    );
  };

  useEffect(() => {
    const getGuruPort = () => {
      // take the guru portfolios, find out the difference
      // rank the gurus, based on their similarities
      setShowSpinner(true);
      getGuruSim(allowedGurus, portResult)
        .then((val) => {
          //console.log(val);
          setGuru(val);
          return val;
        })
        .then((val) => {
          getStyle(val.guru.portchar).then((val) => setGuruStyle(val));

          getInd(val.guru.portchar).then((val) => {
            setGuruIndDist(val.indDist);
          });
          getIndDiv(val.nInd, val.guru.portchar).then((val) => {
            setGuruIndDivScore(val.indDivScore);
            setGuruIndDivMsg(val.indDivMsg);
          });
          getStockDiv(val.guru.portchar).then((val) => {
            setGuruDivScore(val.divScore);
            setGuruDivMsg(val.divMsg);
            setTimeout(() => setShowSpinner(false), 3000);
          });
        });
    };
    if (allowedGurus.length > 0) {
      getGuruPort();
    }
    return () => {};
  }, [allowedGurus, portResult]);

  useEffect(() => {
    const analyze = async () => {
      // execute this once to interpret results

      getInd(portResult).then((val) => {
        setIndBoost(val.indBoost);
        setIndDist(val.indDist);
        getIndDiv(val.nInd, portResult).then((val) => {
          setIndDivScore(val.indDivScore);
          setIndDivMsg(val.indDivMsg);
        });
      });
      getStockDiv(portResult).then((val) => {
        setDivScore(val.divScore);
        setDivMsg(val.divMsg);
      });

      setMeanPortRet(
        0.0388 * portResult.size +
          0.156 * portResult.value +
          0.1446 * portResult.momentum +
          0.1612 * portResult.profit +
          -0.4381
      );

      setSdPortRet(
        -0.8418 * portResult.size -
          -0.7398 * portResult.value -
          -0.8003 * portResult.momentum +
          0.2832 * portResult.profit +
          0.2301 * portResult.hhi +
          8.2603 * portResult.hhiInd +
          11.5204
      );

      console.log(portResult);

      // getPortScore(divScore, indDivScore).then((val) => {
      //   setPortScore(val.score);
      //   setPortMsg(val.msg);
      // });

      // analyze styles
      getStyle(portResult).then((val) => setStyles(val));
    };

    analyze().then(() => {
      //setShowSpinner(false);
    });
  }, [portResult]);

  useEffect(() => {
    const getAddress = () => {
      // generate an address
      if (thisAddress === "") {
        const nanoid = customAlphabet(
          "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
          10
        );
        const addressTemp = nanoid();
        setThisAddress(`https://portdoc.web.app/${addressTemp}`);
        setAddress(addressTemp);
      } else {
        setResultUploaded(true);
      }
    };
    getAddress();
    return () => {};
  }, []);

  const checkUpload = () => {
    // check if result has been uploaded
    // if not, upload the result
    const headers = {
      "Access-Control-Request-Headers": "Content-Type",
      "Content-Type": "application/json",
    };
    //console.log("here here");
    if (!resultUploaded) {
      //console.log('here')

      var resultUpload = result;
      resultUpload["guru"] = guru.guru.name;
      axios
        .post(
          `${endpoint}/postport`,
          { result: resultUpload, address: address },
          headers
        )
        .then(() => setResultUploaded(true));
    }
  };

  useEffect(() => {
    // fetch guru data
    const headers = { "Content-Type": "application/json" };
    //console.log("submit", portfolio);
    // data should have two components:
    setShowSpinner(true);
    axios
      .get(endpoint + "/getgurujson", headers)
      .then((res) => {
        return res.data;
      })
      .then((guruPort) => {
        // get all gurus
        setAllGurus(guruPort);
        // if there is a guru in result, then we set that guru as the only guru allowed
        if (result.guru) {
          // console.log(result.guru)
          // console.log(guruPort)
          setAllowedGurus(guruPort.filter((g) => g.name === result.guru));
        } else {
          setAllowedGurus(guruPort);
          // console.log('allgurus')
        }
        setShowSpinner(false);
      });

    return () => {};
  }, [portResult]);

  let render;
  if (!showSpinner) {
    render = (
      <Container className="mt-3">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="home" title="Summary">
            <Row className="mt-3 mb-3">
              <Col>
                <Card bg="secondary" text="light" className="border-0 mb-3">
                  <Card.Title className="mt-2 ml-2">
                    Portfolio Risk and Return Porfile
                  </Card.Title>
                  <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-secondary">
                    <Row>
                      <Col>
                        {" "}
                        <h4>Average Return in the a Year</h4>{" "}
                        <h4>{(Math.round(meanPortRet * 100 * 12 - 4 * 100) / 100).toFixed(2)}%</h4>
                      </Col>
                      <Col>
                        {" "}
                        <h4>Portfolio Volatility in a Year </h4>{" "}
                        <h4>{(Math.round(sdPortRet * 100 * Math.sqrt(12)) / 100).toFixed(2)}%</h4>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col>* These figures are forecasted based on historical performances of stock portfolios with similar compositions. The realized return and volatilty of your portfolio could differ significantly. </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          {/* </Tab>
          <Tab eventKey="guru" title="Guru Comparison"> */}
            <Row className="mt-3 mb-3">
              <Col>
                <Card bg="secondary" text="light" className="border-0 mb-3">
                  <Card.Title className="mt-2 ml-2">
                    This portfolio is {allGurus === allowedGurus && "most"}{" "}
                    similar to {guru.guru && guru.guru.name}:{" "}
                  </Card.Title>
                  <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-secondary">
                    <Row>
                      <Col>
                        <h4>
                          Your portfolio similarity to{" "}
                          {guru.guru && guru.guru.name}
                          's:{" "}
                          {(
                            10 -
                            ((guru.styleSim / 16) * 40 +
                              (guru.indSim / Math.sqrt(2)) * 10) /
                              5
                          ).toFixed(2)}
                          /10
                        </h4>
                        <p>
                          {/* <Button
                            variant="secondary"
                            onClick={() => setKey("guruport")}
                            className="ml-2"
                          >
                            Stock holdings of {guru.guru && guru.guru.name}
                          </Button> */}
                        </p>
                        {guru.guru && guru.guru.description}{" "}
                        <a
                          href={guru.guru && guru.guru["description link"]}
                          target="_blank"
                        >
                          Read More{" "}
                        </a>{" "}
                        {isMobileOrTablet ? (
                          <Button
                            variant="danger"
                            className="ml-3"
                            onClick={handleOnClick}
                          >
                            Share this report
                          </Button>
                        ) : (
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={
                              <Popover id="popover-basic">
                                <Popover.Title as="h3">
                                  Share this report
                                </Popover.Title>
                                <Popover.Content>
                                  <Sharing
                                    thisAddress={thisAddress}
                                    checkUpload={checkUpload}
                                  />
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <Button variant="danger" className="ml-3">
                              Share this report
                            </Button>
                          </OverlayTrigger>
                        )}
                      </Col>

                      <Col>
                        <h5>Compare with another guru</h5>

                        {guru.guru &&
                          allGurus.map((g) => (
                            <Button
                              variant={
                                g.name === guru.guru.name
                                  ? "secondary mr-2 mt-1"
                                  : "outline-secondary mr-2 mt-1"
                              }
                              onClick={() => setAllowedGurus([g])}
                            >
                              {g.name}
                            </Button>
                          ))}
                        <Button
                          variant="secondary mr-2 mt-1"
                          onClick={() => setAllowedGurus(allGurus)}
                        >
                          Get Most Similar Guru
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {guru.guru && (
              <StyleChart
                portchar={portResult}
                guruPortchar={guru.guru.portchar}
                guru={guru}
              />
            )}
            <Row className="mt-3">
              <Col>
                <Card className="border-1 text-secondary bg-light">
                  <Card.Title className="mt-2 ml-2">
                    Your Portfolio Style
                  </Card.Title>
                  <Card.Body className="mt-2 ml mr mb bg-light text-dark">
                    <span>{getStyleComment(styles)}</span>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="border-1 text-secondary bg-light">
                  <Card.Title className="mt-2 ml-2">
                    {guru.guru && guru.guru.name}'s Portfolio Style
                  </Card.Title>
                  <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-dark">
                    {getStyleComment(guruStyle)}
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {guru.guru && (
              <IndustryChart
                portchar={portResult}
                guruPortchar={guru.guru.portchar}
                guru={guru}
              />
            )}
            <Row>
              <Col>
                <Card className="border-1 text-secondary bg-light">
                  <Card.Title className="mt-2 ml-2">
                    Your Industry Allocation
                  </Card.Title>

                  <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-dark">
                    <span className="mt-3">{getIndAllocation(portResult)}</span>
                  </Card.Body>
                </Card>
              </Col>

              <Col>
                <Card className="border-1 text-secondary bg-light">
                  <Card.Title className="mt-2 ml-2">
                    {guru.guru && guru.guru.name}'s Industry Allocation:
                  </Card.Title>
                  <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-dark">
                    {guru.guru && getIndAllocation(guru.guru.portchar)}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <DivChart
              portScore={portScore}
              divScore={divScore}
              indDivScore={indDivScore}
              guruPortScore={guruPortScore}
              guruDivScore={guruDivScore}
              guruIndDivScore={guruIndDivScore}
            />
            <Row className="mt-3">
              <Col>
                <Card className="border-1 text-secondary bg-light">
                  <Card.Title className="mt-2 ml-2 ">
                    Your Portfolio Diversification Score: {portScore}/5
                  </Card.Title>
                  <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-dark">
                    <ul>
                      <li>This portfolio is {portMsg}</li>
                      <li>Stock Diversification Score: {divScore}/5</li>
                      <i> {divMsg}</i>
                      <li>Industry Diversification Score: {indDivScore}/5</li>
                      <i>{indDivMsg}</i>
                      {indDivScore <= 3 && (
                        <Button
                          className="mt-2"
                          variant="secondary"
                          onClick={() => {
                            setModalIndShow(true);
                          }}
                        >
                          {" "}
                          Explore industries to improve diversification
                        </Button>
                      )}
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="border-1 text-secondary bg-light">
                  <Card.Title className="mt-2 ml-2 ">
                    {guru.guru && guru.guru.name}'s Portfolio Diversification
                    Score: {guruPortScore}/5
                  </Card.Title>
                  <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-dark">
                    <ul>
                      <li>This portfolio is {guruPortMsg} </li>
                      <li>Stock Diversification Score: {guruDivScore}/5</li>
                      <li>
                        Industy Diversification Score: {guruIndDivScore}/5
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>

          <Tab eventKey="myport" title="Your Portfolio">
            <DisplayHoldings
              setModalShow={setModalShow}
              setModalSubject={setModalSubject}
              assetsResult={assetsResult}
            />
          </Tab>

          <Tab
            eventKey="guruport"
            title={guru.guru && `${guru.guru.name}'s Portfolio`}
          >
            {guru.guru && (
              <DisplayHoldings
                setModalShow={setModalShow}
                setModalSubject={setModalSubject}
                assetsResult={guru.guru.assets}
              />
            )}
          </Tab>
          <Tab eventKey="detail" title="Detailed Report">
            <DetailReport
              setEmail={setEmail}
              message={message}
              getReport={getReport}
            />
          </Tab>
        </Tabs>
      </Container>
    );
  } else
    render = (
      <>
        {" "}
        <MySpinner text="Analyzing" />{" "}
      </>
    );

  return (
    <Container>
      <IndSuggest
        indBoost={indBoost}
        setModalIndShow={setModalIndShow}
        getIndComment={getIndComment}
        modalIndShow={modalIndShow}
      />
      <ExplainStyle
        setModalShow={setModalShow}
        modalShow={modalShow}
        s={modalSubject}
        setShowExplorer={setShowExplorer}
      />
      {render}
    </Container>
  );
}
