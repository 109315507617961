import React from "react";
import { Row, Button } from "react-bootstrap";
import NStocksSlider from "./NStocksSlider";

export default function ResultFooter({
  handleNext,
  topNStocks,
  setTopNStocks,
  maxVal,
}) {
  var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "150px",
    width: "100%",
  };

  var phantom = {
    display: "block",
    padding: "20px",
    height: "180px",
    width: "100%",
  };
  return (
    <div>
      <div style={phantom} />
      <div style={style}>
        <Row className="justify-content-center">
          <NStocksSlider
            maxVal={maxVal}
            topNStocks={topNStocks}
            setTopNStocks={setTopNStocks}
          />
        </Row>
        <Row className="justify-content-center mb-5 mt-2">
          <Button
            variant="outline-primary"
            onClick={() => {
              handleNext(3, -1);
            }}
          >
            <b>Back</b>
          </Button>
        </Row>
      </div>
    </div>
  );
}
