import React, { useState } from "react";
import { Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function InvestAmtFormFooter({}) {
  var style = {
    backgroundColor: "#343a40",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "100px",
    width: "100%",
  };

  var phantom = {
    display: "block",
    padding: "20px",
    height: "180px",
    width: "100%",
  };

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showCredit, setShowCredit] = useState(false);

  return (
    <div>
      <Modal show={showDisclaimer} onHide={() => setShowDisclaimer(false)}>
        <Modal.Header closeButton>
          <h4>Disclaimer</h4>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <h5> NO INVESTMENT ADVICE</h5>
          The Content is for informational purposes only, you should not
          construe any such information or other material as legal, tax,
          investment, financial, or other advice. Nothing contained on our Site
          constitutes a solicitation, recommendation, endorsement, or offer by
          this site or any third party service provider to buy or sell any
          securities or other financial instruments in this or in in any other
          jurisdiction in which such solicitation or offer would be unlawful
          under the securities laws of such jurisdiction. All Content on this
          site is information of a general nature and does not address the
          circumstances of any particular individual or entity. Nothing in the
          Site constitutes professional and/or financial advice, nor does any
          information on the Site constitute a comprehensive or complete
          statement of the matters discussed or the law relating thereto. This
          site is not a fiduciary by virtue of any person’s use of or access to
          the Site or Content. You alone assume the sole responsibility of
          evaluating the merits and risks associated with the use of any
          information or other Content on the Site before making any decisions
          based on such information or other Content. In exchange for using the
          Site, you agree not to hold this site, its affiliates or any third
          party service provider liable for any possible claim for damages
          arising from any decision you make based on information or other
          Content made available to you through the Site.
          <h5 className="mt-3">INVESTMENT RISKS</h5>
          There are risks associated with investing in securities. Investing in
          stocks, bonds, exchange traded funds, mutual funds, and money market
          funds involve risk of loss. Loss of principal is possible. Some high
          risk investments may use leverage, which will accentuate gains &
          losses. Foreign investing involves special risks, including a greater
          volatility and political, economic and currency risks and differences
          in accounting methods. A security’s or a firm’s past investment
          performance is not a guarantee or predictor of future investment
          performance.
        </Modal.Body>
      </Modal>
      <Modal show={showCredit} onHide={() => setShowCredit(false)}>
        <Modal.Header closeButton>
          <h4>Credit</h4>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div>
            Icons made by{" "}
            <a href="https://www.freepik.com" title="Freepik">
              Freepik
            </a>{" "}
            and{" "}
            <a href="https://smashicons.com/" title="Smashicons">
              Smashicons
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
        </Modal.Body>
      </Modal>
      <div style={phantom} />
      <div style={style}>
        <Row className="justify-content-center bg-dark text-light">
          {/* Got 3 mins? */}
          <span
            className="mr-5"
            onClick={() => {
              setShowDisclaimer(true);
            }}
          >
            Disclaimer
          </span>
          <span
            className="ml-3 mr-3"
            onClick={() => {
              setShowCredit(true);
            }}
          >
            Credit
          </span>
          <span className="ml-5">
            <span
              onClick={(e) => {
                window.location.href = "mailto:portdoc.analyses@gmail.com";
              }}
            >
              Feedback
            </span>
          </span>
        </Row>
        <Row className="justify-content-center mb-5"></Row>
      </div>
    </div>
  );
}
