import React from "react";
import { Navbar, Container, Nav, Image } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";

const NavBar = () => {
  return (
    <Navbar bg="dark">
      <Container className="d-flex justify-content-center">
        <div className="navbar-header">
          <a
            className="navbar-brand"
            onClick={() => {
              window.location = "/";
            }}
            href="#"
          >
            {" "}
            <h2 className="text-light">
              {/* <Image
                style={{ maxWidth: 45, height: "auto" }}
                src="light-bulb.svg"
                fluid={true}
              /> */}
              <b> Invest Champ </b>
            </h2>
          </a>
        </div>
      </Container>
      <Nav></Nav>
    </Navbar>
  );
};

export default NavBar;
