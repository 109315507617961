import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Suggest from "./AutoSuggest";
import NumericInput from "react-numeric-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
export default function PortfolioForm({
  handleSubmit,
  portfolio,
  setAssetTicker,
  companies,
  getShares,
  deleteAsset,
  addAsset,
  setAssetShares,
  loadPortf,
  reset
}) {
  //console.log(portfolio);

  let [, setState] = useState();
  function handleUpdate() {
    //passing empty object will re-render the component
    setState({});
  }

  return (
    <Form onSubmit={handleSubmit}>
      <table>
        <tr>
          <th>
            <h4>Ticker</h4>
          </th>

          <th>
            <h4>Shares</h4>
          </th>
          <th></th>
        </tr>
        {portfolio.map((asset, index) => (
          <tr>
            <td>
              <Form.Group controlId="ticker">
                <Suggest
                  setAssetTicker={setAssetTicker}
                  assetId={asset["assetId"]}
                  assetIndex={index}
                  ticker={asset["ticker"]}
                  companies={companies}
                />
              </Form.Group>
            </td>
            <td>
              <Form.Group controlId="shares">
                <NumericInput
                  size={10}
                  onChange={(val) => {
                    setAssetShares(index, asset["assetId"], val);
                  }}
                  value={getShares(asset["shares"])}
                />
              </Form.Group>
            </td>
            <td>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => {
                  deleteAsset(asset["assetId"]);
                }}
                className="ml-2 mb-3"
              >
                x
              </Button>
            </td>
          </tr>
        ))}
        {/* <tr>
    <td>
      <Button variant="outline-dark" onClick={addAsset}>
        {" "}
        <h6>+ Add Ticker </h6>
      </Button>{" "}
    </td>
    <td>Load Recent Portfolio</td>
  </tr> */}
      </table>
      <table style={{ width: "100%" }}>
        <tr>
          <td width="60%">
            <Button className="mt-2" variant="outline-dark" onClick={addAsset}>
              {" "}
              <h6>+ Add Ticker </h6>
            </Button>{" "}
          </td>

          <td className="rightAlign">
            <Button className="mt-2" variant={"danger"} onClick={handleSubmit}>
              <h6>
                {" "}
                <b>
                  <FontAwesomeIcon icon={faSearch} /> Portfolio Scan{" "}
                </b>
              </h6>
            </Button>
          </td>
        </tr>
        <tr>
          <td>
            <Button variant="dark" className="mt-3" onClick={reset}>
              Clear
            </Button>
          </td>
          <td className="rightAlign">
            <Button variant="dark" className="mt-3" onClick={() => {loadPortf(); handleUpdate()}}>
              Load Last Portfolio
            </Button>
          </td>
        </tr>
      </table>
    </Form>
  );
}
