import React, { useState, useEffect } from "react";
import { Row, Button, Alert, Card } from "react-bootstrap";

export default function GuruFormFooter({
  pctToBeAllocated,
  handleNext,

  alertMsg,
}) {
  var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "150px",
    width: "100%",
  };

  var phantom = {
    display: "block",
    padding: "20px",
    height: "180px",
    width: "100%",
  };

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertMsg.trim().length > 0) setShowAlert(true);
    else setShowAlert(false);
    return () => {};
  }, [alertMsg]);

  return (
    <div>
      <div style={phantom} />
      <div style={style}>
        {showAlert ? (
          <Row className="justify-content-center mb-1">
            <Alert
              variant="danger"
              show={showAlert}
              onClose={() => setShowAlert(false)}
              dismissible
            >
              {alertMsg}
            </Alert>
          </Row>
        ) : (
          <Row className="justify-content-center mb-2">
            <Card
              className={pctToBeAllocated < -0.009 ? "bg-danger" : " bg-dark"}
              border="light"
            >
              <div className="mt-2 mb-1 ml-3 mr-3">
                {/* {pctToBeAllocated < -0.009 && ( */}
                <h5 className="text-light">
                  <b>
                    Percentage to be allocated:{" "}
                    {Math.floor(pctToBeAllocated * 100) / 100} %{" "}
                  </b>
                </h5>
              </div>
            </Card>
          </Row>
        )}
        <Row className="justify-content-center mt-3 mb-3">
          <Button
            className="mr ml-1"
            variant="outline-primary"
            onClick={() => {
              handleNext(2, -1);
            }}
          >
            <b>Back</b>
          </Button>
          <Button
            className="ml-4"
            onClick={() => {
              handleNext(2, 1);
            }}
          >
            <b>Next</b>
          </Button>
        </Row>
      </div>
    </div>
  );
}
