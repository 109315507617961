import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label,
  LabelList,
} from "recharts";

export default class SimpleBar extends PureComponent {
  render() {
    return (
      <BarChart
        width={600}
        height={200}
        barSize={50}
        maxBarSize={5}
        data={this.props.data}
        tickLine={false}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis
          domain={[0, 5]}
          label={{ value: "Score", angle: -90, position: "insideLeft" }}
        />
        <Tooltip />
        <Bar name="Your Portfolio" dataKey="yp" fill="#d9d90f" />
        <Bar name="Guru Portfolio" dataKey="gp" fill="#0fc1d9"></Bar>
        <LabelList dataKey="name" fill="#FFFFFF" angle="45" />
        <Legend />
      </BarChart>
    );
  }
}
