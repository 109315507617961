import React from "react";
import { Card, Row, Col, Jumbotron, Button } from "react-bootstrap";

export default function Footer({ setShowDisclaimer }) {
  var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "150px",
    width: "100%",
  };

  var phantom = {
    display: "block",
    padding: "20px",
    height: "180px",
    width: "100%",
  };
  return (
    <div>
      <div style={phantom} />
      <div style={style}>
        {/* <Jumbotron className="mt-5 text-center bg-light"> */}
          <h3>Accessible Portfolio Analysis </h3>
          <Row className="mt-4">
            <Col className="justify-content-md-end">
              <span>
                <a onClick={() => setShowDisclaimer(true)}>Disclaimer</a>
              </span>{" "}
            </Col>
            <Col>V 0.2</Col>
            <Col className="justify-content-md-center">
              <span>
                <a href="mailto:portdoc.analyses@gmail.com">Send Suggestions</a>
              </span>
            </Col>
          </Row>
        {/* </Jumbotron> */}
      </div>
    </div>
  );
}
