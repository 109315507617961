import React from "react";
import { Helmet } from "react-helmet";

export default function SEO() {
  return (
    <div>
      <Helmet>
        <title>Invest Champ - Investing like a pro</title>
        <meta
          name="description"
          content="Start investing like a pro. We customize a professional stock portfolio based on investments of a group of famed investors and your own preference for free."
        />
        <meta
          name="keywords"
          content="investment,free,portfolio,customize,professional"
        />
      </Helmet>
    </div>
  );
}
