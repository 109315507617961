import React from 'react'
import {Row, Col, Card, Button } from 'react-bootstrap';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    LineIcon,
    LinkedinIcon,
    RedditIcon,
    TwitterIcon,
    FacebookMessengerShareButton,
  } from "react-share";

export default function Sharing({thisAddress, checkUpload}) {
    return (
        
        <>
          
          <EmailShareButton beforeOnClick={checkUpload} url={thisAddress}>
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
          <FacebookShareButton beforeOnClick={checkUpload} url={thisAddress}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton beforeOnClick={checkUpload} url={thisAddress}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <RedditShareButton beforeOnClick={checkUpload} url={thisAddress}>
            <RedditIcon size={32} round={true} />
          </RedditShareButton>
          
        </>
      
    )
}
