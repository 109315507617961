//import { Tooltip } from 'bootstrap';
import React, { PureComponent } from 'react';
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, Tooltip
} from 'recharts';

export default class SimpleRadar extends PureComponent {
  
  render () {
    // console.log(this.props.data)
    return (
      <RadarChart cx={250} cy={160} outerRadius={120} width={465} height={320} data={this.props.data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis domain={[0, 5]} />
        <Radar name="Your Portfolio" dataKey="yp" stroke="#d9d90f" fill="#d9d90f" fillOpacity={0.6} />
        <Radar name="Guru Portfolio" dataKey="gp" stroke="#0fc1d9" fill="#0fc1d9" fillOpacity={0.6} />
        <Legend />
        <Tooltip />
      </RadarChart>
    );
  }
}
