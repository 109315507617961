import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, AreaChart, Area } from 'recharts';

export default class Example extends PureComponent {

  render() {
    return (
      <div>
        <h5>Monthly Average Returns (%) *</h5>
        <LineChart
          width={600}
          height={200}
          data={this.props.data}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Date" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="Average" stroke="#8884d8" fill="#8884d8" dot={false} />
          <Line type="monotone" dataKey="Market" stroke="#B93029" fill="#B93029" dot={false} />
          <Brush />
        </LineChart>
          <h6>Average represents your portfolio average return. Market is based on S&P 500 Index</h6>

        <h5>Monthly High/Low Returns (%) * </h5>

        <AreaChart
          width={600}
          height={200}
          data={this.props.data}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Date" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="Low" stroke="#82ca9d" fill="#82ca9d" />
          <Area type="monotone" dataKey="High" stroke="#82ca9d" fill="#82ca9d" />
        </AreaChart>
      </div>
    );
  }
}
