import React from "react";
import { Spinner, Row, Container } from "react-bootstrap";

export default function MySpinner({ text }) {
  return (
    <Container className="justify-content-center" flex>
      <Row className="justify-content-center mt-5 mb-5" flex>
        <Spinner animation="grow" role="status" variant="dark"></Spinner>
        <span> </span>
        <Spinner
          className="ml-3"
          animation="grow"
          role="status"
          variant="dark"
        ></Spinner>
        <span> </span>
        <Spinner
          className="ml-3"
          animation="grow"
          role="status"
          variant="dark"
        ></Spinner>
      </Row>
      <Row className="justify-content-center mt-5 mb-5">
        <h5>
          <b>{text}</b>
        </h5>
      </Row>
    </Container>
  );
}
