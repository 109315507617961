import React from "react";
import { Table } from "react-bootstrap";
import ShowTicker from "../common/ShowTicker";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function ResultTable({ myAllocation, showWholeShares }) {
  return (
    <Table striped bordered hover className="mt-2" size="sm">
      <thead>
        <tr>
          <th>Ticker</th>
          <th>Shares</th>
          <th>Dollar Value</th>
          <th>% Portfolio</th>
        </tr>
      </thead>
      <tbody>
        {myAllocation.map((asset) => (
          <tr key={asset.ticker}>
            <td>
              <ShowTicker ticker={asset.ticker.toUpperCase()} />
            </td>
            <td>
              {numberWithCommas(
                showWholeShares ? asset.shares : asset.shares.toFixed(3)
              )}
            </td>
            <td>$ {numberWithCommas(asset.value.toFixed(3))}</td>
            <td>{(asset.portpct * 100).toFixed(3)} %</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
