import React, { useState,useEffect } from "react";
import Autosuggest from "react-autosuggest";

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(companies, value) {
  const escapedValue = escapeRegexCharacters(value.trim());
  const regex = new RegExp("^" + escapedValue, "i");
  //return companies.filter((c) => regex.test(c.ticker) || regex.test(c.comname));
  return companies
    .filter((c) => regex.test(c.ticker))
    .sort(function (a, b) {
      return a.ticker.length - b.ticker.length;
    });
}

function getSuggestionTicker(suggestion) {
  return suggestion.ticker;
}

function renderSuggestion(suggestion) {
  return (
    <span>
      {suggestion.ticker} - {suggestion.comname}
    </span>
  );
}

export default function AutoSuggest(props) {
  //console.log(props.ticker)
  const [tickerValue, setTickerValue] = useState(props.ticker);
  
  const [tickerSuggestions, setTickerSuggestions] = useState([]);
  const [comnameValue, setComnameValue] = useState("");

  useEffect(() => { setTickerValue(props.ticker)}, [props.ticker] )

  const onTickerChange = (event, { newValue }) => {
    setTickerValue(newValue.toUpperCase());
    props.setAssetTicker(props.assetIndex, props.assetId, newValue);
  };

  const onTickerSuggestionsFetchRequested = ({ value }) => {
    setTickerSuggestions(getSuggestions(props.companies, value).slice(0, 5));
  };

  const onTickerSuggestionsClearRequested = () => {
    setTickerSuggestions([]);
  };

  const onTickerSuggestionSelected = (event, { suggestion }) => {
    setComnameValue(suggestion.comname);
  };

  const tickerInputProps = {
    placeholder: "Ticker",
    value: tickerValue,
    onChange: onTickerChange,
  };

  return (
    <Autosuggest
      suggestions={tickerSuggestions}
      onSuggestionsFetchRequested={onTickerSuggestionsFetchRequested}
      onSuggestionsClearRequested={onTickerSuggestionsClearRequested}
      onSuggestionSelected={onTickerSuggestionSelected}
      getSuggestionValue={getSuggestionTicker}
      renderSuggestion={renderSuggestion}
      inputProps={tickerInputProps}
    />
  );
}
