import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  DropdownButton,
  Dropdown,
  Row,
  Col,
  Card,
  Container,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import { endpoint, industries, industryDict, stockStyle } from "../constants";
import axios from "axios";
import MySpinner from "./common/MySpinner";
import ShowTicker from "./common/ShowTicker";
import StockFormContext from './context/AllContext'

export default function StockExplorer(props) {
  // this module filters and display stocks following style/industry
  const [query, setQuery] = useState("");
  const [queryValue, setQueryValue] = useState(null);

  const [result, setResult] = useState([]);
  const [cursor, setCursor] = useState(1);
  const [updating, setUpdating] = useState(false);
  const [direction, setDirection] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    const getStocks = () => {
      //console.log("query", query, queryValue, direction);
      const headers = { "Content-Type": "application/json" };

      var refTicker = "";
      var reqBody;
      if (direction === 1) {
        refTicker = result[19][0];
      } else if (direction === -1) {
        refTicker = result[0][0];
      }
      if (query !== "" && queryValue !== null) {
        reqBody = { query, queryValue, refTicker, direction };
      } else {
        reqBody = { refTicker, direction };
      }

      //      console.log(result);
      //      console.log(reqBody);
      setShowSpinner(true);
      axios.post(endpoint + "/explorer", reqBody, headers).then((res) => {
        //console.log(res);
        setResult(res["data"]);
        setShowSpinner(false);
      });
    };
    if (updating) {
      getStocks();
      setUpdating(false);
    }
    return () => {};
  }, [updating]);

  useEffect(() => {
    //console.log(props.showExplorer.query)
    if (props.showExplorer && props.showExplorer.query) {
      changeQuery(props.showExplorer.query, props.showExplorer.queryValue);
    }
    return () => {};
  }, []);

  const changeQuery = (q, qVal) => {
    setQuery(q);
    setQueryValue(qVal);
    setCursor(1);
    setDirection(0);
    setUpdating(true);
  };

  const changeCursor = (direction) => {
    if (direction === -1) {
      setDirection(-1);
      setUpdating(true);
    } else {
      setDirection(1);
      setUpdating(true);
    }
    setCursor(cursor + direction);
  };

  const getTable = () => {
    return (
      <Table striped bordered hover className="mt-4" size="sm">
        <thead>
          <tr>
            <th> Ticker</th> <th>Firm Name</th>
          </tr>
        </thead>
        <tbody>
          {result.map((row) => (
            <tr>
              <td>
                <ShowTicker ticker={row[0]} />
              </td>
              <td>{row[1].name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-3">
        <Card className="bg-white border-0 text-secondary">
          <h2>Stock Explorer</h2>{" "}
          <Button variant='outline-dark' onClick={() => props.setShowExplorer(null)}>
            {" "}
            Back to Report
          </Button>
        </Card>
      </Row>

      <Row className="justify-content-md-center mt-1">
        <Button
          className="mt-1 mr-1"
          variant='outline-dark'
          onClick={async () => {
            changeQuery("", null);
          }}
        >
          Reset
        </Button>
        <ButtonGroup>
          <DropdownButton
            className="mt-1"
            variant="secondary"
            id="industry"
            title={
              industryDict[queryValue] === undefined
                ? "Select Industry"
                : industryDict[queryValue]
            }
          >
            {industries.map((ind) => (
              <Dropdown.Item
                onClick={() => {
                  changeQuery("industry", ind);
                }}
              >
                {industryDict[ind]}
              </Dropdown.Item>
            ))}
          </DropdownButton>

          {stockStyle.map((s) => (
            <DropdownButton
              className="mt-1 "
              variant="secondary"
              id={s}
              title={
                query !== s ? `Select ${s} style` : `${s} style = ${queryValue}`
              }
            >
              {[1, 2, 3, 4, 5].map((l) => (
                <Dropdown.Item
                  onClick={async () => {
                    changeQuery(s, l);
                  }}
                >
                  {l}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          ))}
        </ButtonGroup>
      </Row>
      {showSpinner && <MySpinner />}
      <Row>{getTable()}</Row>
      <Row className="justify-content-md-center mt-1 mb-5">
        {" "}
        {cursor !== 1 && (
          <Button
            onClick={() => {
              changeCursor(-1);
            }}
          >
            Prev Page
          </Button>
        )}{" "}
        {result.length === 20 && (
          <Button onClick={() => changeCursor(1)}>Next Page</Button>
        )}
      </Row>
      </Container>
  );
}
