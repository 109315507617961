import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import SimpleBar from "./common/SimpleBar";
export default function DivChart({
  portScore,
  divScore,
  indDivScore,
  guruPortScore,
  guruDivScore,
  guruIndDivScore,
}) {
  return (
    <Row className="mt-3">
      <Col>
        <Card bg="secondary" text="light" className="border-0">
          <Card.Title className="mt-2 ml-2 ">Diversification Scores</Card.Title>
          <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-secondary">
              <Row>
                  <Col>
                  <h4>Diversification helps reduce portfolio risks</h4>
                  <h6></h6>
                  </Col>
                  <Col>
            <SimpleBar
              data={[
                { name: "Overall", yp: portScore, gp: guruPortScore },
                { name: "Stock ", yp: divScore, gp: guruDivScore },
                {
                  name: "Industry ",
                  yp: indDivScore,
                  gp: guruIndDivScore
                },
              ]}
            /></Col>
            </Row>
          </Card.Body>
          
        </Card>
      </Col>
    </Row>
  );
}
