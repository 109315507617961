import React, { useContext } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import SimpleRadar from "./common/SimpleRadar";
import { stockStyle } from "../constants";
import { StockFormContext } from "./context/AllContext";

export default function StyleChart({ portchar, guruPortchar, guru }) {
  const styleData = stockStyle.map((s) => ({
    name: s.toUpperCase(),
    yp: Math.round(portchar[s.toLowerCase()]*100) / 100,
    gp: Math.round(guruPortchar[s.toLowerCase()]*100) / 100,
  }));

  const {
    result,
    setResult,
    setShowExplorer,
    setEmail,
    getReport,
    message,
    thisAddress,
    setThisAddress,
  } = useContext(StockFormContext);
  return (
    <Row className="mt-3">
      <Col>
        <Card bg="secondary" text="light" className="border-0 mb-3">
          <Card.Title className="mt-2 ml-2">
            Portfolio Style Comparison
          </Card.Title>

          <Card.Body className="mt-2 ml-0 mr-0 mb-0 bg-light text-dark">
            <Row>
              <Col>
                {" "}
                <Card className="border-0 mt-3 ml-3 mr-3 bg-light text-secondary">
                  <Card.Title className="mt-3 ml-3 mr-3">
                    <h4>Your style similarity to {guru.guru && guru.guru.name}'s:</h4>
                  </Card.Title>
                  <Card.Body>
                    {" "}
                    <h2>
                      {guru.styleSim &&
                        (10 - (guru.styleSim / 16) * 10).toFixed(2)}
                      /10
                    </h2>
                    <Button
                      variant="secondary"
                      className="mt-5"
                      onClick={() => setShowExplorer(true)}
                    >
                      Explore Stocks by Style
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <SimpleRadar data={styleData} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
